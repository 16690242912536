// src/HomePage.js
import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import { useNavigate, useParams } from 'react-router-dom';
import './HomePage.css';

const HomePage = () => {
  const [certificateDetails, setCertificateDetails] = useState(null);
  const [referenceId, setReferenceId] = useState('');
  const [showDetails, setShowDetails] = useState(false);
  const navigate = useNavigate();
  const { internId } = useParams(); // Get internId from the URL params

  // Public Google Sheets CSV URL
  const csvUrl = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vRZDafscIb2xzOcLIh0n2r7h2-fLpa9uNHWVre_o4UcF0aBB1mun_Txm5TeLyAjocwUPWVYKiECXiDS/pub?gid=0&single=true&output=csv';

  // Function to handle search by reference ID (internId)
  const handleSearch = (idToSearch) => {
    fetch(csvUrl)
      .then(response => response.text())
      .then(data => {
        Papa.parse(data, {
          header: true,  // Make sure headers are true to correctly map fields
          complete: (result) => {
            const found = result.data.find(row => row.InternID === idToSearch); // Adjusted to use header field
            if (found) {
              setCertificateDetails({
                internId: found.InternID,       // INTERNID
                name: found.Name,               // NAME
                domain: found.Domain,           // DOMAIN
                batch: found.Batch,             // BATCH
                internshipStatus: found["Internship Status"],   // INTERNSHIP STATUS
              });
              setShowDetails(true);
            } else {
              setShowDetails(false);
            }
          },
        });
      })
      .catch(error => {
        console.error('Error fetching the CSV file:', error);
      });
  };

  // Automatically search if there's an internId passed via URL
  useEffect(() => {
    if (internId) {
      setReferenceId(internId); // Set the reference ID to the internId from URL
      handleSearch(internId);   // Trigger search
    }
  }, [internId]);

  // Handle manual search by user input
  const onSearchClick = () => {
    if (referenceId) {
      handleSearch(referenceId);
      navigate(`/certificate-details/${referenceId}`); // Update URL with reference ID
    }
  };

  return (
    <div className="container">
      <h1>SSB AUTOMATIONS CERTIFICATE VERIFICATION PORTAL</h1>
      <input
        type="text"
        value={referenceId}
        onChange={(e) => setReferenceId(e.target.value)}
        placeholder="Enter Registration Id"
      />
      <button onClick={onSearchClick}>Search</button>

      {showDetails && certificateDetails ? (
        <div className="details">
          <strong>Information</strong>
          <table className="table">
            <tbody>
              <tr>
                <td><strong>Intern ID:</strong></td>
                <td>{certificateDetails.internId}</td>
              </tr>
              <tr>
                <td><strong>Name:</strong></td>
                <td>{certificateDetails.name}</td>
              </tr>
              <tr>
                <td><strong>Domain:</strong></td>
                <td>{certificateDetails.domain}</td>
              </tr>
              <tr>
                <td><strong>Batch:</strong></td>
                <td>{certificateDetails.batch}</td>
              </tr>
              <tr>
                <td><strong>Internship Status:</strong></td>
                <td>{certificateDetails.internshipStatus}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div className="no-details">Nothing to show...</div>
      )}
    </div>
  );
};

export default HomePage;
