// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import CertificateDetails from './CertificateDetails';
import Footer from './Footer'; // Import Footer component
import { ToastContainer } from 'react-toastify'; // Import ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Import Toast CSS
import './App.css'; // Import App CSS for layout adjustments

const App = () => {
  return (
    <Router>
      <div className="app">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/certificate-details/:rid" element={<CertificateDetails />} />
        </Routes>
        <Footer /> {/* Include the Footer component */}
        <ToastContainer /> {/* Add ToastContainer here */}
      </div>
    </Router>
  );
};

export default App;
