// src/CertificateDetails.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Papa from 'papaparse';
import { toast } from 'react-toastify'; // Import toast
import 'react-toastify/dist/ReactToastify.css'; // Ensure CSS is imported

const CertificateDetails = () => {
  const { rid } = useParams(); // Capture 'rid' from URL
  const [certificateDetails, setCertificateDetails] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [notificationShown, setNotificationShown] = useState(false); // Flag to control notifications

  // Public Google Sheets CSV URL
  const csvUrl = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vRZDafscIb2xzOcLIh0n2r7h2-fLpa9uNHWVre_o4UcF0aBB1mun_Txm5TeLyAjocwUPWVYKiECXiDS/pub?gid=0&single=true&output=csv';

  useEffect(() => {
    // Fetch the CSV file from the URL
    fetch(csvUrl)
      .then(response => response.text())
      .then(data => {
        // Parse the CSV data
        Papa.parse(data, {
          header: true,
          complete: (result) => {
            const found = result.data.find(row => row.InternID === rid);

            if (found) {
              setCertificateDetails({
                internId: found.InternID,
                name: found.Name,
                domain: found.Domain,
                batch: found.Batch,
                internshipStatus: found["Internship Status"],
              });
              setShowDetails(true);

              // Trigger success notification only if not already shown
              if (!notificationShown) {
                toast.success("Verification Successful");
                setNotificationShown(true);
              }
            } else {
              setShowDetails(false);

              // Trigger failure notification only if not already shown
              if (!notificationShown) {
                toast.error("Verification Failed");
                setNotificationShown(true);
              }
            }
          },
        });
      })
      .catch(error => {
        console.error('Error fetching the CSV file:', error);
        toast.error("Error fetching the data.");
      });
  }, [rid, notificationShown, csvUrl]);

  return (
    <div className="container">
      <h1>Certificate Details</h1>
      {showDetails && certificateDetails ? (
        <div className="details">
          <strong>Information</strong>
          <table className="table">
            <tbody>
              <tr>
                <td><strong>Intern ID:</strong></td>
                <td>{certificateDetails.internId}</td>
              </tr>
              <tr>
                <td><strong>Name:</strong></td>
                <td>{certificateDetails.name}</td>
              </tr>
              <tr>
                <td><strong>Domain:</strong></td>
                <td>{certificateDetails.domain}</td>
              </tr>
              <tr>
                <td><strong>Batch:</strong></td>
                <td>{certificateDetails.batch}</td>
              </tr>
              <tr>
                <td><strong>Internship Status:</strong></td>
                <td>{certificateDetails.internshipStatus}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <div className="no-details">Nothing to show...</div>
      )}
    </div>
  );
};

export default CertificateDetails;
